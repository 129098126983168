import { backendApi } from "@/services/api"
import { MouldDifferentiation } from "./mould-differentiation"

const differentiationApi = backendApi.injectEndpoints({
  endpoints: build => ({
    putMouldDifferentiation: build.mutation<
      MouldDifferentiation,
      PutMouldDifferentiation
    >({
      query: request => ({
        url: `/inspections/differentiation`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Differentiation"],
    }),
    deleteMouldDifferentiation: build.mutation<
      DeleteMouldDifferentiationResponse,
      DeleteMouldDifferentiationRequest
    >({
      query: request => ({
        url: `/inspections/differentiation`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: ["Differentiation"],
    }),
    listDifferentiations: build.query<List, ListDifferentiationsRequest>({
      query: request => ({
        url: `/inspections/differentiation/list`,
        method: "GET",
        params: {
          sampleSetId: request.sampleSetId,
          limit: request.limit,
          offset: request.offset,
        },
      }),
      providesTags: ["Differentiation"],
    }),
  }),
})

export const {
  usePutMouldDifferentiationMutation,
  useDeleteMouldDifferentiationMutation,
  useListDifferentiationsQuery,
} = differentiationApi

export interface PutMouldDifferentiation {
  mouldId: string
  sampleSetId: string
  processId: string
  value?: number
  overgrown?: boolean
}

export interface ListDifferentiationsRequest {
  sampleSetId: string
  limit: number
  offset: number
}

export interface List {
  count: number
  items: MouldDifferentiation[]
}

export interface DeleteMouldDifferentiationRequest {
  mouldId: string
  sampleSetId: string
}

export interface DeleteMouldDifferentiationResponse {}
