import { useParams } from "react-router-dom"
import { PersistenceStateUsingContext } from "@/shared/persistence-state/persistence-state-info"
import { InspectionReportPdf } from "@/routes/processes/[processId]/process/inspections/[reportId]/_components/inspection-report-pdf"
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page"

export function ReportRoute() {
  const { reportId } = useParams()

  const links = [
    {
      to: "results",
      label: "Ergebnisse",
    },
    {
      to: "analytics",
      label: "Zählungen",
    },
    {
      to: "sample-sets",
      label: "Probensets",
    },
    {
      to: "samples",
      label: "Proben",
    },
    {
      to: "contacts",
      label: "Kontakt",
    },
  ]

  return (
    <ArtefactPage>
      <ArtefactPageHeader>
        <ArtefactPageNavigation>
          {links.map(link => (
            <ArtefactPageNavigationLink to={link.to}>
              {link.label}
            </ArtefactPageNavigationLink>
          ))}
        </ArtefactPageNavigation>
        <ArtefactPageActions>
          <PersistenceStateUsingContext className="mr-2" />
          <InspectionReportPdf reportId={reportId!} />
        </ArtefactPageActions>
      </ArtefactPageHeader>
      <ArtefactPageContent>
        <ArtefactPageNavigationOutlet />
      </ArtefactPageContent>
    </ArtefactPage>
  )
}
