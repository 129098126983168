import { backendApi } from "@/services/api"
import { Batch } from "./batch"
import { GrowthMedium, SampleType } from "../../samples/sample-set/sample-set"

const batchesApi = backendApi.injectEndpoints({
  endpoints: build => ({
    listBatches: build.query<List, ListRequest>({
      query: request => ({
        url: `/inspections/batches/list`,
        method: "GET",
        params: {
          limit: request.limit,
          offset: request.offset,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Batches" as const,
                id,
              })),
              { type: "Batches", id: "LIST" },
            ]
          : [{ type: "Batches", id: "LIST" }],
    }),
    getBatch: build.query<Batch, GetRequest>({
      query: request => ({
        url: `/inspections/batches`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Batches", id: request.id },
      ],
    }),
    putBatch: build.mutation<Batch, PutRequest>({
      query: request => ({
        url: `/inspections/batches`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Batches"],
    }),
  }),
})

export const { usePutBatchMutation, useGetBatchQuery, useListBatchesQuery } =
  batchesApi

export interface PutRequest {
  id: string
  batchNumber: number
  sampleType: SampleType
  growthMedium: GrowthMedium
  expiryDate: string
}

export interface GetRequest {
  id: string
}

export interface ListRequest {
  limit: number
  offset: number
}

export interface List {
  count: number
  data: Batch[]
}
