import { RouterProvider } from "react-router"
import router from "@/routes/router"
import { ErrArtefactNotReadyContextProvider } from "@/shared/service-manager/artefact/err-artefact-not-ready"
import { PersistenceContextProvider } from "./shared/persistence-state/provider"

function App() {
  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <RouterProvider router={router} />
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  )
}

export default App
