import { useParams } from "react-router-dom"
import { useContactsListQuery } from "@/services/backend/contacts/contact/service"
import { Contact } from "@/services/backend/contacts/contact/contact"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import { useGetDeliveryQuery } from "@/services/backend/deliveries/delivery/service"
import {
  ContactInfoCard,
  ContactInfoCardSkeleton,
} from "@/shared/components/domain/contacts/contact-info-card"

export default function ContactRoute() {
  const { deliveryId } = useParams()
  const { data: delivery } = useGetDeliveryQuery(
    { id: deliveryId as string },
    { skip: !deliveryId },
  )

  const {
    data: contacts,
    error,
    isLoading,
  } = useContactsListQuery(
    { customerId: delivery?.customerId as string, limit: 0, offset: 0 },
    { skip: !delivery },
  )

  if (isLoading) {
    return <ContactInfoCardSkeleton />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <div className="space-y-4">
      <div className="grid w-fit grid-cols-1 gap-4 lg:grid-cols-2">
        {contacts?.data?.map((contact: Contact) => (
          <ContactInfoCard contact={contact} />
        ))}
      </div>
    </div>
  )
}
