import { MouldDifferentiation } from "@/services/backend/differentiation/mould-differentiation"
import { Procedure } from "@/services/backend/procedures/procedure/procedure"
import { GrowthMedium } from "@/services/backend/samples/sample-set/sample-set"

export function getMouldCount(procedure: Procedure): number {
  let count = 0
  const incubations = procedure.incubation.filter(
    inc => inc.growthMedium !== GrowthMedium.Caso,
  )

  incubations.forEach(inc => {
    inc.measurements.forEach(measure => {
      if (measure.isCurrent) {
        count += measure.measurement ? measure.measurement : 0
      }
    })
  })

  return count
}

export function getDifferentiatedCount(diffs: MouldDifferentiation[]): number {
  let count = 0
  diffs.forEach(diff => {
    if (diff.value != null) {
      count += diff.value
    } else if (diff.overgrown != null && diff.overgrown) {
      count += 1
    }
  })
  return count
}
