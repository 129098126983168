import type {
  ColumnDef,
  Table as ReactTable,
  VisibilityState,
} from "@tanstack/react-table"
import { ReactElement } from "react"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { SampleSetNumber } from "@/shared/components/domain/sample-set/sample-set-number"
import { Procedure } from "@/services/backend/procedures/procedure/procedure"
import { sampleTypes } from "@/shared/components/enum-labels"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import { H4 } from "@/shared/components/typography"
import { useListProceduresQuery } from "@/services/backend/procedures/procedure/service"
import { CollapsibleDataTable } from "@/shared/components/collapsible-data-table"
import MouldResult from "@/shared/components/domain/moulds/mould-result"
import { getMouldCount } from "@/shared/components/domain/moulds/mould-count"
import DifferentiationMetadataRow from "./differentiation-metadata-row"
import { PutDifferentiationDialog } from "./put-differentiation-dialog"

const createTableColumns = (): ColumnDef<Procedure>[] => [
  {
    id: "sampleSet",
    accessorKey: "procedure",
    header: "Probenset",
    cell: ({ row }) => {
      const procedureData = row.original
      return <SampleSetNumber sampleSetId={procedureData.sampleSetId} />
    },
  },
  {
    id: "sampleType",
    accessorKey: "sampleType",
    header: "Probentyp",
    cell: ({ row }) => {
      const procedureData = row.original
      return <div>{sampleTypes[procedureData.sampleType]}</div>
    },
  },
  {
    id: "mouldResult",
    accessorKey: "id",
    header: "Schimmelpilze / Hefen",
    cell: ({ row }) => {
      const procedureData = row.original
      return (
        <div className="flex items-center space-x-2">
          <MouldResult
            procedure={procedureData}
            sampleSetId={procedureData.sampleSetId}
          />
        </div>
      )
    },
  },
  {
    id: "actions",
    accessorKey: "id",
    header: "Aktionen",
    cell: ({ row }) => {
      const procedureData = row.original
      return (
        <div className="flex items-center space-x-2">
          <PutDifferentiationDialog
            sampleSetId={procedureData.sampleSetId}
            disabled={getMouldCount(procedureData) === 0}
          />
        </div>
      )
    },
  },
]

interface DifferentiationTableProps {
  processId: string
  procedureType: ProcedureType
  label: string
  visibilityState?: VisibilityState
}

function DifferentiationTable({
  processId,
  procedureType,
  label,
  visibilityState = {},
}: DifferentiationTableProps): ReactElement {
  const {
    data: list,
    error,
    isLoading,
    isFetching,
  } = useListProceduresQuery(
    {
      processId: processId as string,
      procedureType: procedureType as ProcedureType,
      limit: 0,
      page: 0,
    },
    { refetchOnMountOrArgChange: true },
  )

  const cols = createTableColumns()
  const table = useDataTableController(cols, list?.data || [], visibilityState)

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={cols} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (list != null && list.data != null && list.data.length > 0) {
    return (
      <div className="flex w-full flex-col space-y-4">
        <H4>{label}</H4>
        <DataTableContainer className="w-full">
          <CollapsibleDataTable
            table={table}
            columns={cols}
            collapsibleTriggerId="sampleSet"
            renderCollapsibleContent={(procedure: Procedure) => (
              <DifferentiationMetadataRow procedure={procedure} />
            )}
          />
        </DataTableContainer>
      </div>
    )
  }

  return <div />
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}

export default DifferentiationTable
