export enum ProcessStatus {
  Created = "Created",
  Scheduled = "Scheduled",
  IncubationStarted = "IncubationStarted",
  FirstMeasurementDone = "FirstMeasurementDone",
  SecondMesasurementDone = "SecondMesasurementDone",
  DifferentiationDone = "DifferentiationDone",
  InspectionReportCreated = "InspectionReportCreated",
  InspectionReportReleased = "InspectionReportReleased",
  Completed = "Completed",
}

export enum ProcessKind {
  Inspection = "Inspection",
}

export interface Process {
  id: string
  processKind: ProcessKind
  customerId: string
  topic: string
  incubationStart: string
  completed: boolean
  status: ProcessStatus
  createdAt: string
}
