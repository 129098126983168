import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { useParams } from "react-router-dom"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableContainer,
  DataTableFilters,
  useDataTableController,
} from "@/shared/components/data-table"
import { formatDate, formatTime } from "@/shared/utilities/date"
import { useListSamplesByProcessQuery } from "@/services/backend/samples/sample-set/sample-service"
import { SampleSetNumber } from "@/shared/components/domain/sample-set/sample-set-number"
import { CollapsibleDataTable } from "@/shared/components/collapsible-data-table"
import { SampleTypeComponent } from "@/shared/components/domain/sample-set/sample-type-component"
import {
  EvaluableType,
  GrowthMedium,
  Sample,
} from "@/services/backend/samples/sample-set/sample-set"
import SampleMetadataRow from "./sample-metadata-row"

const sampleType: { [key: string]: string } = {
  [GrowthMedium.Caso]: "Caso",
  [GrowthMedium.Malt]: "Malz",
  [GrowthMedium.DG18]: "DG18",
}

const evaluableType: { [key: string]: string } = {
  [EvaluableType.Evaluable]: "Auswertbar",
  [EvaluableType.NotEvaluable]: "Nicht auswertbar",
}

const tableColumns: ColumnDef<Sample>[] = [
  {
    id: "id",
    accessorKey: "sampleSetId",
    header: "Probenset",
    cell: ({ row }) => {
      const sampleData = row.original
      return <SampleSetNumber sampleSetId={sampleData.sampleSetId} />
    },
  },
  {
    id: "sampleType",
    accessorKey: "sampleSetId",
    header: "Probentyp",
    cell: ({ row }) => {
      const sampleData = row.original
      return <SampleTypeComponent sampleSetId={sampleData.sampleSetId} />
    },
  },
  {
    id: "evaluableType",
    accessorKey: "evaluableType",
    header: "Auswertbarkeit",
    cell: ({ row }) => {
      const sampleData = row.original
      return <div>{evaluableType[sampleData.evaluableType]}</div>
    },
  },
  {
    id: "growthMedium",
    accessorKey: "growthMedium",
    header: "Probentyp",
    cell: ({ row }) => {
      const sampleData = row.original
      return <div>{sampleType[sampleData.growthMedium]}</div>
    },
  },
  {
    id: "creationDate",
    accessorKey: "creationDate",
    header: "Probenahmedatum",
    cell: ({ row }) => {
      const deliveryData = row.original
      return (
        <div className="items-start">
          <div className="flex flex-col items-start">
            <span className="font-bold">
              {formatDate(deliveryData.createdAt)}
            </span>
            <span>{formatTime(deliveryData.createdAt)}</span>
          </div>
        </div>
      )
    },
  },
]

export function SampleTable() {
  const { processId, reportId } = useParams()
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useListSamplesByProcessQuery(
    {
      processId: processId as string,
      limit: 0,
      offset: 0,
      ownerId: reportId as string,
      ownerType: "inspection.Inspection",
    },
    { refetchOnMountOrArgChange: true },
  )

  const table = useDataTableController(tableColumns, list?.data || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <DataTableContainer>
      <DataTableFilters className="flex justify-between space-x-2" />
      <CollapsibleDataTable
        table={table}
        columns={tableColumns}
        renderCollapsibleContent={(sample: Sample) => (
          <SampleMetadataRow sample={sample} />
        )}
        collapsibleTriggerId="id"
      />
    </DataTableContainer>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}
