import { createBrowserRouter } from "react-router-dom"
import { RootErrorBoundary } from "@/routes/root-error-boundary"
import Root from "@/routes/root"
import ProcessesRoute from "@/routes/processes/processes-route"
import DeliveriesRoute from "@/routes/deliveries/deliveries-route"
import DeliveryRoute from "@/routes/deliveries/[deliveryId]/delivery/delivery"
import { deliveryRoutes } from "@/routes/deliveries/[deliveryId]/delivery/delivery-routes"
import CustomersRoute from "@/routes/customers/customers-route"
import BaseDataRoute from "@/routes/base-data/base-data"
import { baseDataRoutes } from "@/routes/base-data/base-data-routes"
import { customerRoutes } from "@/routes/customers/[customerId]/customer-routes"
import CustomerRoute from "@/routes/customers/[customerId]/customer-route"
import ProcessRoute from "./processes/[processId]/process/process"
import { processRoutes } from "./processes/[processId]/process/process-routes"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "base-data",
        element: <BaseDataRoute />,
        children: baseDataRoutes,
      },
      {
        path: "deliveries",
        element: <DeliveriesRoute />,
      },
      {
        path: "deliveries/:deliveryId",
        element: <DeliveryRoute />,
        children: deliveryRoutes,
      },
      {
        path: "processes",
        element: <ProcessesRoute />,
      },
      {
        path: "processes/:processId",
        element: <ProcessRoute />,
        children: processRoutes,
      },
      {
        path: "customers",
        element: <CustomersRoute />,
      },
      {
        path: "customers/:customerId",
        element: <CustomerRoute />,
        children: customerRoutes,
      },
    ],
    errorElement: <RootErrorBoundary />,
  },
])

export default router

/* function Alert404() {
  return (
    <Alert variant="destructive">
      <Terminal className="h-4 w-4" />
      <AlertTitle>404</AlertTitle>
      <AlertDescription>Nicht gefunden</AlertDescription>
    </Alert>
  )
} */

/* function Alert403() {
  return (
    <Alert variant="destructive">
      <Terminal className="h-4 w-4" />
      <AlertTitle>403</AlertTitle>
      <AlertDescription>Nicht erlaubt</AlertDescription>
    </Alert>
  )
} */
