import { ApiKeyTable } from "@/routes/customers/[customerId]/api-key/_components/api-key-table"
import { CreateApiKeyForm } from "@/routes/customers/[customerId]/api-key/_components/create-api-key"
import { useParams } from "react-router-dom"

export default function ApiKey() {
  const { customerId } = useParams()
  return (
    <div className="space-y-4">
      {customerId != null && <CreateApiKeyForm customerId={customerId} />}
      {customerId != null && <ApiKeyTable />}
    </div>
  )
}
