import { useListDifferentiationsQuery } from "@/services/backend/differentiation/service"
import { Procedure } from "@/services/backend/procedures/procedure/procedure"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import { MouldLabel } from "@/shared/components/domain/moulds/mould-label"
import { Badge } from "@/shared/shadcn/components/ui/badge"
import { Card } from "@/shared/shadcn/components/ui/card"
import { ReactElement } from "react"

interface DifferentiationMetadataRowProps {
  procedure: Procedure
}

function DifferentiationMetadataRow({
  procedure,
}: DifferentiationMetadataRowProps): ReactElement {
  const { data: differentiations, error } = useListDifferentiationsQuery({
    sampleSetId: procedure.sampleSetId,
    limit: 0,
    offset: 0,
  })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (
    differentiations &&
    differentiations?.items != null &&
    differentiations.items.length === 0
  ) {
    return <p>Keine Pilze vorhanden</p>
  }

  return (
    <div className="flex flex-col space-y-4">
      {differentiations != null &&
        differentiations.items != null &&
        differentiations.items.map(mould => (
          <Card className="flex flex-col space-y-2 p-4">
            <div className="flex flex-row space-x-1">
              <p className="font-bold">Art: </p>
              <MouldLabel id={mould.mouldId} />
            </div>
            <div className="flex flex-row space-x-1">
              <p className="font-bold">Zählung: </p>
              <p>{mould.value}</p>
            </div>
            <div className="flex flex-row space-x-1">
              <p className="font-bold">Überwachsen: </p>
              <div>
                {mould.overgrown ? (
                  <Badge className="bg-red-700 text-white">Ja</Badge>
                ) : (
                  <Badge className="bg-green-700 text-white">Nein</Badge>
                )}
              </div>
            </div>
          </Card>
        ))}
    </div>
  )
}

export default DifferentiationMetadataRow
