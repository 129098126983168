import { backendApi } from "@/services/api"
import { EvaluableType } from "@/services/backend/samples/sample-set/sample-set"

const sampleEvaluationApi = backendApi.injectEndpoints({
  endpoints: build => ({
    evaluateSample: build.mutation<
      EvaluateSampleResponse,
      EvaluateSampleRequest
    >({
      query: request => ({
        url: `/inspections/sample-evaluation`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Sample"],
    }),
  }),
})

export const { useEvaluateSampleMutation } = sampleEvaluationApi

export interface EvaluateSampleRequest {
  evaluableType: EvaluableType
  sampleIds: string[]
  sampleErrorState: SampleErrorState[]
}

export interface SampleErrorState {
  isChecked: boolean
  id: string
}

export interface EvaluateSampleResponse {}
