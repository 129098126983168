import { backendApi } from "@/services/api"
import {
  ApprovalViewModel,
  ProcedureType,
  ReferenceViewModel,
  SampleSet,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"

const sampleSetApi = backendApi.injectEndpoints({
  endpoints: build => ({
    putSampleSet: build.mutation<SampleSet, PutRequest>({
      query: request => ({
        url: `/inspections/sampleset`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["SampleSet"],
    }),
    sampleSetList: build.query<List, ListRequest>({
      query: request => ({
        url: `/inspections/sampleset/list`,
        method: "GET",
        params: {
          customerId: request.customerId,
          deliveryId: request.deliveryId,
          limit: request.limit,
          offset: request.offset,
          ownerId: request.ownerId,
          ownerType: request.ownerType,
        },
      }),
      providesTags: ["SampleSet"],
    }),
    sampleSetListByProcess: build.query<List, ListByProcessRequest>({
      query: request => ({
        url: `/inspections/sampleset/listbyprocess`,
        method: "GET",
        params: {
          processId: request.processId,
          sampleType: request.sampleType,
          ownerId: request.ownerId,
          ownerType: request.ownerType,
        },
      }),
      providesTags: ["SampleSet"],
    }),
    sampleSetListByDelivery: build.query<List, ListByDeliveryRequest>({
      query: request => ({
        url: `/inspections/sampleset/listbydelivery`,
        method: "GET",
        params: {
          deliveryId: request.deliveryId,
          ownerId: request.ownerId,
          ownerType: request.ownerType,
        },
      }),
      providesTags: ["SampleSet"],
    }),
    getSampleSet: build.query<SampleSet, GetRequest>({
      query: request => ({
        url: `/inspections/sampleset`,
        method: "GET",
        params: request,
      }),
      providesTags: ["SampleSet"],
    }),
    assignToProcess: build.mutation<SampleSet, AssignToProcessRequest>({
      query: request => ({
        url: `/inspections/sampleset/assign-to-process`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["SampleSet", "Delivery"],
    }),
    sampleSetListByReference: build.query<
      ListByReference,
      ListByReferenceRequest
    >({
      query: request => ({
        url: `/inspections/sampleset/listbyreference`,
        method: "GET",
        params: {
          deliveryId: request.deliveryId,
          ownerId: request.ownerId,
          ownerType: request.ownerType,
        },
      }),
      providesTags: ["SampleSet"],
    }),
    sampleSetListForApproval: build.query<
      ListForApproval,
      ListForApprovalRequest
    >({
      query: () => ({
        url: `/inspections/sampleset/listforapproval`,
        method: "GET",
      }),
      providesTags: ["SampleSet"],
    }),
  }),
})

export const {
  useGetSampleSetQuery,
  useSampleSetListQuery,
  useSampleSetListByReferenceQuery,
  useAssignToProcessMutation,
  useSampleSetListByProcessQuery,
  useSampleSetListByDeliveryQuery,
  usePutSampleSetMutation,
  useSampleSetListForApprovalQuery,
} = sampleSetApi

export interface PutRequest {
  id: string
  customerId: string
  deliveryId: string
  procedureType: ProcedureType
  referenceId?: string
  sampleSetNumber?: string
  comment?: string
  externalComment?: string
  sampleType?: SampleType
  airSamplerId?: string
}

export interface PutCommentRequest {
  id: string
  comment: string
}

export interface PutCommentResponse {}

export interface GetRequest {
  id: string
}

export interface DeleteRequest {
  id: string
}

export interface ListByProcessRequest {
  processId: string
  sampleType: SampleType
  ownerId?: string
  ownerType?: string
}

export interface ListByDeliveryRequest {
  deliveryId: string
  ownerId?: string
  ownerType?: string
}

export interface ListByReferenceRequest {
  deliveryId: string
  ownerId?: string
  ownerType?: string
}

export interface ListByReference {
  count: number
  data: ReferenceViewModel[]
}

export interface ListForApprovalRequest {}

export interface ListForApproval {
  count: number
  data: ApprovalViewModel[]
}

export interface ListRequest {
  customerId: string
  deliveryId: string
  limit: number
  offset: number
  ownerId?: string
  ownerType?: string
}

export interface List {
  count: number
  data: SampleSet[]
}

export interface AssignToProcessRequest {
  processId: string
  referenceId: string
}
