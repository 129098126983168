import { MouldListTable } from "@/routes/base-data/sample/moulds/_components/mould-list-table"
import { PutMouldDialogForm } from "@/routes/base-data/sample/moulds/_components/put-mould-dialog"

export function MouldsRoute() {
  return (
    <div className="flex flex-col space-y-4">
      <PutMouldDialogForm />
      <MouldListTable />
    </div>
  )
}
