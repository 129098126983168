import { Outlet, useParams } from "react-router-dom"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import {
  NavigationGroup,
  ProcessPage,
  ProcessPageMain,
  ProcessPageMainContent,
  ProcessPageMainHeader,
  ProcessPageSidebar,
  ProcessPageSidebarNavigation,
  ProcessSidebarTrigger,
} from "@/shared/service-manager/default-ui/process-page/process-page"
import { BreadcrumbsToArtefact } from "@/shared/service-manager/default-ui/process-page/breadcrumbs-to-artefact"
import { Separator } from "@/shared/shadcn/components/ui/separator"
import {
  Binoculars,
  ClipboardList,
  FlaskRound,
  Layout,
  Phone,
  Shapes,
  TestTubes,
} from "lucide-react"

export default function ProcessRoute() {
  const { processId } = useParams()
  const {
    data: process,
    isLoading,
    error,
  } = useGetProcessQuery({
    id: processId ?? "",
  })

  if (isLoading) {
    return null
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <ProcessPage>
      <ProcessPageSidebar className="pt-16">
        <ProcessPageSidebarNavigation groups={sidebarNavigation} />
      </ProcessPageSidebar>
      <ProcessPageMain>
        <ProcessPageMainHeader className="justify-between">
          <div className="flex items-center gap-2">
            <ProcessSidebarTrigger />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <BreadcrumbsToArtefact process={process!} />
          </div>
        </ProcessPageMainHeader>
        <ProcessPageMainContent>
          <Outlet />
        </ProcessPageMainContent>
      </ProcessPageMain>
    </ProcessPage>
  )
}

const sidebarNavigation: NavigationGroup[] = [
  {
    label: "Allgemein",
    items: [
      {
        label: "Übersicht",
        icon: Layout,
        to: "overview",
      },
      {
        label: "Ansprechpartner",
        icon: Phone,
        to: "contact",
      },
    ],
  },
  {
    label: "Meilensteine",
    items: [
      {
        label: "Dokumentation",
        icon: FlaskRound,
        to: "documentation",
      },
      {
        label: "Probensetübersicht",
        icon: TestTubes,
        to: "set-overview",
      },
      {
        label: "Zählung",
        icon: Binoculars,
        to: "analytics",
      },
      {
        label: "Differenzierung",
        icon: Shapes,
        to: "differentiation",
      },
      {
        label: "Prüfberichte",
        icon: ClipboardList,
        to: "inspections",
      },
    ],
  },
]
