import { ReactElement } from "react"

interface CommentLabelProps {
  comment: string | undefined
}

export default function CommentLabel({
  comment,
}: CommentLabelProps): ReactElement {
  if (!comment || comment === "") {
    return <div>Kein Kommentar vorhanden</div>
  }

  return <div>{comment}</div>
}
