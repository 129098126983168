import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from "@/shared/shadcn/components/ui/card"
import { useParams } from "react-router-dom"
import ConfirmationDialog from "@/shared/components/domain/confirmation/confirmation-dialog"
import { Button } from "@/shared/shadcn/components/ui/button"
import { useStartProcessMutation } from "@/services/backend/processes/process/service"
import { useGetDeliveryQuery } from "@/services/backend/deliveries/delivery/service"
import { State } from "@/services/backend/deliveries/state/state"
import { SampleSetTable } from "./sample-set-table"

export default function PackageContents() {
  const { deliveryId } = useParams()

  const { data } = useGetDeliveryQuery({ id: deliveryId as string })

  const [startProcess] = useStartProcessMutation()

  const startHandler = () => {
    startProcess({ deliveryId: deliveryId as string })
  }

  return (
    <Card className="w-full">
      <CardHeader className="flex items-start">
        <CardTitle className="text-3xl">Paketinhalt</CardTitle>
        <CardDescription>
          Folgende Probensets sind im angekündigten Paket enhalten
        </CardDescription>
      </CardHeader>
      <CardContent className="flex w-full flex-col space-y-2">
        {data != null && data.deliveryState === State.Delivered && (
          <ConfirmationDialog
            trigger={
              <Button className="w-fit" variant="outline">
                <p>Prozesse starten</p>
              </Button>
            }
            title="Prozesse starten"
            description="Hiermit wird für jede Referenz in diesem Paket ein neuer Prozess gestartet."
            onConfirm={() => startHandler()}
          />
        )}
        {deliveryId != null && <SampleSetTable deliveryId={deliveryId} />}
      </CardContent>
    </Card>
  )
}
