import { PutErrorDialogForm } from "@/routes/base-data/sample/errors/_components/put-error-dialog"
import { SampleErrorListTable } from "@/routes/base-data/sample/errors/_components/sample-error-list-table"

export function ErrorsRoute() {
  return (
    <div className="flex flex-col space-y-4">
      <PutErrorDialogForm />
      <SampleErrorListTable />
    </div>
  )
}
