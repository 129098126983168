import { useParams } from "react-router-dom"
import { ArtefactNotReadyAlert } from "@/shared/service-manager/artefact/artefact-sheet"
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready"
import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page"
import { ReportListTable } from "./report-list-table"
import CreateReportButton from "./create-report-button"

export function ReportsIndexRoute() {
  const { processId } = useParams()
  const { notReadyError } = useErrArtefactNotReady()

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <CreateReportButton processId={processId!} />
      </MilestonePageHeader>
      <MilestonePageContent>
        <ArtefactNotReadyAlert error={notReadyError} artefact="Inspektion" />
        <ReportListTable processId={processId!} />
      </MilestonePageContent>
    </MilestonePage>
  )
}
