import { useParams } from "react-router"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page"
import { PersistenceStateUsingContext } from "@/shared/persistence-state/persistence-state-info"
import DifferentiationTable from "./differentiation-table"

export function DifferentiationIndexRoute() {
  const { processId } = useParams()

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <div className="flex w-full flex-row justify-end">
          <PersistenceStateUsingContext />
        </div>
      </MilestonePageHeader>
      <MilestonePageContent>
        <DifferentiationTable
          label="2N-RLT"
          procedureType={ProcedureType.RLT2N}
          processId={processId as string}
        />
      </MilestonePageContent>
    </MilestonePage>
  )
}
