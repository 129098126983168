import { useParams } from "react-router"
import {
  useGetInspectionQuery,
  useInspectionUpdateContactMutation,
} from "@/services/backend/inspections/service"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import { ContactCardWithMutation } from "@/shared/components/domain/contacts/contact-card"
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready"

export function ContactsIndexRoute() {
  const { reportId } = useParams()
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady()
  const { data, isLoading, error } = useGetInspectionQuery({
    id: reportId as string,
  })

  if (isLoading || !data) {
    return <div />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <ContactCardWithMutation
      id={reportId as string}
      contact={data.inspection.contact}
      mutation={useInspectionUpdateContactMutation}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "contact.")}
      resetFieldError={field => resetNotReadyErrorField(`contact.${field}`)}
    />
  )
}
