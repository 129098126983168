import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { de } from "date-fns/locale"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shared/shadcn/components/ui/form"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/shadcn/components/ui/popover"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/shadcn/components/ui/dialog"
import { Button } from "@/shared/shadcn/components/ui/button"
import { cn } from "@/shared/shadcn/lib/utils"
import { Calendar } from "@/shared/shadcn/components/ui/calendar"
import { v4 as uuidv4 } from "uuid"
import { usePutBatchMutation } from "@/services/backend/batches/batch/service"
import {
  GrowthMedium,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"
import { Input } from "@/shared/shadcn/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/shadcn/components/ui/select"
import { growthMedia, sampleTypes } from "@/shared/components/enum-labels"

const formSchema = z.object({
  expiryDate: z.coerce.date(),
  batchNumber: z.string(),
  sampleType: z.string(),
  growthMedium: z.string(),
})

export default function PutBatchDialog() {
  const [putBatch] = usePutBatchMutation()
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      expiryDate: new Date(),
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    const selectedDate = values.expiryDate.toISOString()
    putBatch({
      id: uuidv4(),
      batchNumber: Number(values.batchNumber),
      expiryDate: selectedDate,
      growthMedium: values.growthMedium as GrowthMedium,
      sampleType: values.sampleType as SampleType,
    })
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <p>Charge anlegen</p>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Neue Charge anlegen</DialogTitle>
          <DialogDescription>Bitte geben Sie an,</DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-8"
            >
              <FormField
                control={form.control}
                name="batchNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Chargennummer</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Chargennummer"
                        type="number"
                        min="1"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>Chargennummer</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="expiryDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Ablaufdatum</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            className={cn(
                              "w-full pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP", { locale: de })
                            ) : (
                              <span>Datum auswählen</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormDescription>
                      Das Ablaufdatum des Nährmediums
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="growthMedium"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nährmedium</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Bitte Nährmedium auswählen." />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.values(GrowthMedium).map(value => (
                          <SelectItem key={value} value={value}>
                            {growthMedia[value]}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>Nährmedium der Charge</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="sampleType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Probentyp</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Bitte Probentyp auswählen" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.values(SampleType).map(value => (
                          <SelectItem key={value} value={value}>
                            {sampleTypes[value]}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>Der Probentyp der Charge.</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />{" "}
              <Button type="submit">Speichern</Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
