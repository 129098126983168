import { useGetLatestInspectionByProcessQuery } from "@/services/backend/inspections/service"
import { ReactElement } from "react"
import { Skeleton } from "@/shared/shadcn/components/ui/skeleton"
import { formatDate, formatTime } from "@/shared/utilities/date"
import { RTKQueryErrorAlert } from "../errors/rtk-query-error-alert"
import { is404 } from "../errors/parse-r-t-k-query-error"

interface InspectionCreatedAtProps {
  processId: string
}

export function InspectionCreatedAt({
  processId,
}: InspectionCreatedAtProps): ReactElement {
  const { data, isLoading, error } = useGetLatestInspectionByProcessQuery({
    processId: processId as string,
  })

  if (error) {
    if (is404(error)) {
      return <span>Kein Bericht erstellt</span>
    }
    return <RTKQueryErrorAlert error={error} />
  }

  if (isLoading) {
    return <Skeleton className="h-1 w-4" />
  }

  if (!data) {
    return <span className="font-mono">Kein Bericht erstellt</span>
  }

  return (
    <div className="items-start">
      <div className="flex flex-col items-start">
        <span className="font-bold">
          {formatDate(data.inspection.createdAt)}
        </span>
        <span>{formatTime(data.inspection.createdAt)}</span>
      </div>
    </div>
  )
}
