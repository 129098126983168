import type { RouteObject } from "react-router-dom"
import OverviewRoute from "@/routes/deliveries/[deliveryId]/delivery/overview/overview"
import ContactRoute from "@/routes/deliveries/[deliveryId]/delivery/contact/contact"

export const deliveryRoutes: RouteObject[] = [
  {
    path: "overview",
    element: <OverviewRoute />,
    index: true,
  },
  {
    path: "contact",
    element: <ContactRoute />,
  },
]
