import { CommentInputComponent } from "@/shared/components/comment-component"
import { ReactElement, useState } from "react"
import { BatchComponent } from "@/shared/components/domain/samples/batch-component"
import {
  PutSampleRequest,
  usePutSampleMutation,
} from "@/services/backend/samples/sample-set/sample-service"
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce"
import { Card } from "@/shared/shadcn/components/ui/card"
import { Sample } from "@/services/backend/samples/sample-set/sample-set"
import ErrorBadge from "./error-badge"

interface SampleMetadataRowProps {
  sample: Sample
}

function SampleMetadataRow({ sample }: SampleMetadataRowProps): ReactElement {
  const [request, setRequest] = useState<PutSampleRequest>({
    ...sample,
  })
  const [put, { isLoading, error, isSuccess, reset }] = usePutSampleMutation()

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    300,
    { toastError: true },
  )

  return (
    <Card className="flex flex-row items-center justify-between p-4">
      <div className="flex flex-col items-start space-y-2">
        <p className="font-bold">Charge:</p>
        {sample.batchId !== "" && sample.batchId != null ? (
          <BatchComponent batchId={sample.batchId} />
        ) : (
          "Keine Charge gefunden"
        )}
      </div>
      <div className="flex flex-col items-start space-y-2">
        <p className="font-bold">Fehler:</p>
        <div className="flex flex-row space-x-1">
          {sample.sampleErrors != null && sample.sampleErrors.length > 0 ? (
            sample.sampleErrors.map(sampleError => (
              <ErrorBadge sampleError={sampleError} />
            ))
          ) : (
            <p>Keine Fehler vorhanden</p>
          )}
        </div>
      </div>
      <div className="flex flex-col items-start space-y-2">
        <p className="font-bold">Kommentar:</p>
        <CommentInputComponent
          disabled={isLoading}
          comment={sample.comment}
          submitHandler={comment => setRequest({ ...request, comment })}
        />
      </div>
    </Card>
  )
}

export default SampleMetadataRow
