import { useGetAirSamplerQuery } from "@/services/backend/airsamplers/service"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import { skipToken } from "@reduxjs/toolkit/query"

interface AirSamplerLabelProps {
  id: string | undefined
}

export function AirSamplerLabel({ id }: AirSamplerLabelProps) {
  const { data, error } = useGetAirSamplerQuery(id ? { id } : skipToken)

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (!data) return <span>Keinen Luftkeimsammler gefunden</span>

  return <span>{data.airSampler.description}</span>
}
