import { SampleTable } from "@/routes/processes/[processId]/process/documentations/_components/sample-table"
import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page"
import { PersistenceStateUsingContext } from "@/shared/persistence-state/persistence-state-info"
import { useParams } from "react-router-dom"
import AssignToBatchDialog from "@/routes/processes/[processId]/process/documentations/_components/assign-batch-dialog"
import ErrorAssignmentDialog from "@/routes/processes/[processId]/process/documentations/_components/error-assignment-dialog"

export function DocumentationIndexRoute() {
  const { processId } = useParams()
  return (
    <MilestonePage>
      <MilestonePageHeader>
        {processId != null && (
          <div className="flex w-full flex-row justify-between pr-2">
            <div className="flex flex-row space-x-2">
              <AssignToBatchDialog processId={processId} />
              <ErrorAssignmentDialog processId={processId} />
            </div>
            <PersistenceStateUsingContext />
          </div>
        )}
      </MilestonePageHeader>
      <MilestonePageContent>
        <SampleTable processId={processId!} />
      </MilestonePageContent>
    </MilestonePage>
  )
}
