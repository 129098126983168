import { useParams } from "react-router"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import ResultTable from "@/routes/processes/[processId]/process/inspections/[reportId]/results/_components/result-table"

export function ResultIndexRoute() {
  const { processId, reportId } = useParams()

  return (
    <div className="flex flex-col space-y-4">
      <ResultTable
        reportId={reportId as string}
        label="1N-RLT"
        procedureType={ProcedureType.RLT1N}
        processId={processId as string}
        visibilityState={{
          mouldResult: false,
          "corrected-measurement-1": false,
          "corrected-measurement-2": false,
        }}
      />
      <ResultTable
        reportId={reportId as string}
        label="2N-RLT"
        procedureType={ProcedureType.RLT2N}
        processId={processId as string}
        collapsible
      />
    </div>
  )
}
