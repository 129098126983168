import { SampleSetTable } from "@/routes/processes/[processId]/process/set-overview/_components/sample-set-table"
import { useParams } from "react-router-dom"
import { SampleType } from "@/services/backend/samples/sample-set/sample-set"
import { PersistenceStateUsingContext } from "@/shared/persistence-state/persistence-state-info"
import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page"

export function SetOverviewIndexRoute() {
  const { processId } = useParams()

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <div className="flex w-full flex-row justify-end">
          <PersistenceStateUsingContext />
        </div>
      </MilestonePageHeader>
      <MilestonePageContent>
        <div className="flex flex-col">
          <SampleSetTable
            label="Oberflächenkeimproben"
            visibilityState={{ airSamplerHoleCount: false, airSampler: false }}
            processId={processId as string}
            sampleType={SampleType.SurfaceMicrobial}
          />
          <SampleSetTable
            label="Luftkeimproben"
            processId={processId as string}
            sampleType={SampleType.AirMicrobial}
          />
        </div>
      </MilestonePageContent>
    </MilestonePage>
  )
}
