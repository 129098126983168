import {
  Incubation,
  Measurement,
} from "@/services/backend/procedures/procedure/procedure"

interface MeasurementLabelComponentProps {
  incubation: Incubation
  correctedValue?: boolean
}

function MeasurementLabelComponent({
  incubation,
  correctedValue = false,
}: MeasurementLabelComponentProps) {
  const measurement = incubation.measurements.find(
    (measure: Measurement) => measure.isCurrent,
  )

  if (!measurement) {
    return <div>Keine Zählung gefunden</div>
  }

  if (measurement.exceedsThreshold) {
    return (
      <div className="flex space-x-4">
        <div className="flex flex-row space-x-2">&gt;100</div>
      </div>
    )
  }

  return (
    <div className="flex space-x-4">
      {correctedValue ? (
        <div className="flex flex-row space-x-2">
          {measurement.correctedMeasurement}
        </div>
      ) : (
        <div className="flex flex-row space-x-2">{measurement.measurement}</div>
      )}
    </div>
  )
}

export default MeasurementLabelComponent
