import {
  Measurement,
  Procedure,
} from "@/services/backend/procedures/procedure/procedure"
import { growthMedia } from "@/shared/components/enum-labels"
import { Card } from "@/shared/shadcn/components/ui/card"
import { formatDate, formatTime } from "@/shared/utilities/date"
import { de } from "date-fns/locale"
import { ReactElement } from "react"

interface IncubationMetadataRowProps {
  procedure: Procedure
}

function getMeasurementRow(measurement: Measurement): ReactElement {
  return (
    <Card className="flex flex-col p-2">
      <p>
        Zählung:{" "}
        {measurement.exceedsThreshold ? ">100" : measurement.measurement}
      </p>
      <p>Zähler: Tim Olbrich</p>
      <p>Zähldatum: {formatDate(measurement.measurementDate, de)}</p>
      <p>Uhrzeit: {formatTime(measurement.measurementDate, de)}</p>
    </Card>
  )
}

function IncubationMetadataRow({
  procedure,
}: IncubationMetadataRowProps): ReactElement {
  if (
    !procedure ||
    !procedure.incubation ||
    procedure.incubation.length === 0
  ) {
    return <div>Keine Inkubationen gefunden</div>
  }

  return (
    <div className="flex flex-col space-y-4">
      {procedure?.incubation.map(inc => (
        <Card className="flex flex-col space-y-2 p-4">
          <div className="flex flex-row space-x-1">
            <p className="font-bold">Art: </p>
            <p>{growthMedia[inc.growthMedium]}</p>
          </div>
          <div className="flex flex-col">
            <p className="font-bold">Inkubationszeit: </p>
            <p>Min: {inc.minIncubationTime} Stunden</p>
            <p>Max: {inc.maxIncubationTime} Stunden</p>
          </div>
          <div className="flex flex-col">
            <p className="font-bold">Zählzeitraum: </p>
            <p>Start: {formatDate(inc.countStartTimestamp, de)}</p>
            <p>Ende: {formatDate(inc.countEndTimestamp, de)}</p>
          </div>
          <div className="flex flex-col">
            <p className="font-bold">Inkubationstemperatur: </p>
            <p>Min: {inc.minTemperature} Grad Celsisus</p>
            <p>Max: {inc.maxTemperature} Grad Celsisus</p>
          </div>

          {inc.measurements.length > 0 && (
            <div className="flex flex-col">
              <p className="font-bold">Zählungen: </p>
              <div className="flex flex-col space-y-2">
                {inc.measurements.map((measure: Measurement) =>
                  getMeasurementRow(measure),
                )}
              </div>
            </div>
          )}
        </Card>
      ))}
    </div>
  )
}

export default IncubationMetadataRow
