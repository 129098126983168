import { backendApi } from "@/services/api"
import { Measurement } from "@/services/backend/procedures/procedure/procedure"
import {
  ErrorAssignmentViewModel,
  GrowthMedium,
  Sample,
} from "@/services/backend/samples/sample-set/sample-set"

const sampleApi = backendApi.injectEndpoints({
  endpoints: build => ({
    putSample: build.mutation<Sample, PutSampleRequest>({
      query: request => ({
        url: `/inspections/samples`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: [],
    }),
    createMeasurement: build.mutation<Measurement, CreateMeasurementRequest>({
      query: request => ({
        url: `/inspections/samples/measurements`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Sample"],
    }),
    listSamples: build.query<List, ListRequest>({
      query: request => ({
        url: `/inspections/samples/list`,
        method: "GET",
        params: {
          sampleSetId: request.sampleSetId,
          limit: request.limit,
          offset: request.offset,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Sample" as const,
                id,
              })),
              { type: "Sample", id: "LIST" },
            ]
          : [{ type: "Sample", id: "LIST" }],
    }),
    listErrorAssignmentViewModel: build.query<
      ErrorAssignmentViewModelList,
      ListByProcessRequest
    >({
      query: request => ({
        url: `/inspections/samples/list-error-view-model`,
        method: "GET",
        params: {
          processId: request.processId,
          limit: request.limit,
          offset: request.offset,
          ownerId: request.ownerId,
          ownerType: request.ownerType,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Sample" as const,
                id,
              })),
              { type: "Sample", id: "LIST" },
            ]
          : [{ type: "Sample", id: "LIST" }],
    }),
    listSamplesByProcess: build.query<List, ListByProcessRequest>({
      query: request => ({
        url: `/inspections/samples/list-by-process`,
        method: "GET",
        params: {
          processId: request.processId,
          limit: request.limit,
          offset: request.offset,
          ownerId: request.ownerId,
          ownerType: request.ownerType,
        },
      }),
      providesTags: ["Sample"],
    }),
    getSample: build.query<Sample, GetRequest>({
      query: request => ({
        url: `/inspections/samples`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Sample", id: request.id },
      ],
    }),
    assignToBatch: build.mutation<AssignToBatchResponse, AssignToBatchRequest>({
      query: request => ({
        url: `/inspections/samples/assign-to-batch`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Sample"],
    }),
  }),
})

export const {
  usePutSampleMutation,
  useGetSampleQuery,
  useListSamplesQuery,
  useListSamplesByProcessQuery,
  useListErrorAssignmentViewModelQuery,
  useCreateMeasurementMutation,
  useAssignToBatchMutation,
} = sampleApi

export interface GetRequest {
  id: string
}

export interface ListRequest {
  sampleSetId: string
  limit: number
  offset: number
  ownerId?: string
  ownerType?: string
}

export interface ListByProcessRequest {
  processId: string
  limit: number
  offset: number
  ownerId?: string
  ownerType?: string
}

export interface List {
  count: number
  data: Sample[]
}

export interface ErrorAssignmentViewModelList {
  count: number
  data: ErrorAssignmentViewModel[]
}

export interface CreateMeasurementRequest {
  id: string
  sampleId: string
  measurement: number
  growthMedium: GrowthMedium
  measurementDate: Date
  employeeId: string
  controlNumber: number
}

export interface AssignToBatchRequest {
  batchId: string
  sampleIds: string[]
}

export interface AssignToBatchResponse {}

export interface PutSampleRequest {
  id: string
  sampleSetId: string
  customerId: string
  description?: string
  growthMedium?: GrowthMedium
  createdAt?: string
  comment?: string
}
