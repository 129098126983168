import { backendApi } from "@/services/api"
import { ProcessNumberSequence } from "@/services/backend/numbers/process-number/process-number-sequence"
import { ProcessKind } from "../../processes/process/process"

const processNumberApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["NumbersProcessNumberSequence", "NumbersProcessNumber"],
  })
  .injectEndpoints({
    endpoints: build => ({
      numbersProcessNumberCreateSequence: build.mutation<
        ProcessNumberSequence,
        CreateProcessNumberSequenceRequest
      >({
        query: request => ({
          url: `/numbers/process-number/sequence`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["NumbersProcessNumberSequence"],
      }),
      numbersProcessNumberShowSequence: build.query<
        ProcessNumberSequence,
        ShowProcessNumberSequenceRequest
      >({
        query: request => ({
          url: `/numbers/process-number/sequence`,
          method: "GET",
          params: request,
        }),
        providesTags: (_r, _e, request) => [
          {
            type: "NumbersProcessNumberSequence",
            id: request.companyId,
          },
        ],
      }),
      numbersProcessNumberCreate: build.mutation<
        ProcessNumber,
        CreateProcessNumberRequest
      >({
        query: request => ({
          url: `/numbers/process-number`,
          method: "POST",
          params: request,
        }),
        invalidatesTags: ["NumbersProcessNumber"],
      }),
      numbersProcessNumberShow: build.query<
        ProcessNumber,
        ShowProcessNumberRequest
      >({
        query: request => ({
          url: `/numbers/process-number`,
          method: "GET",
          params: request,
        }),
        providesTags: result =>
          result
            ? [
                {
                  type: "NumbersProcessNumber",
                  id: result.processId,
                },
              ]
            : ["NumbersProcessNumber"],
      }),
    }),
  })

export const {
  useNumbersProcessNumberCreateSequenceMutation,
  useNumbersProcessNumberShowSequenceQuery,
  useNumbersProcessNumberCreateMutation,
  useNumbersProcessNumberShowQuery,
} = processNumberApi

export interface CreateProcessNumberSequenceRequest {
  companyId: string
  start: number
}

export interface ShowProcessNumberSequenceRequest {
  companyId: string
}

export interface CreateProcessNumberRequest {
  companyId: string
  processId: string
  processKind: ProcessKind
}

export interface ProcessNumber {
  processId: string
  year: number
  processKind: ProcessKind
  sequenceValue: number
  string: string
}

export interface ShowProcessNumberRequest {
  processId: string
}
