import { useGetLatestInspectionByProcessQuery } from "@/services/backend/inspections/service"
import { ReactElement } from "react"
import { Skeleton } from "@/shared/shadcn/components/ui/skeleton"
import { RTKQueryErrorAlert } from "../errors/rtk-query-error-alert"
import { is404 } from "../errors/parse-r-t-k-query-error"

interface InspectionReleasedFromProps {
  processId: string
}

export function InspectionReleasedFrom({
  processId,
}: InspectionReleasedFromProps): ReactElement {
  const { data, isLoading, error } = useGetLatestInspectionByProcessQuery({
    processId: processId as string,
  })

  if (error) {
    if (is404(error)) {
      return <span>Bericht nicht freigegeben</span>
    }
    return <RTKQueryErrorAlert error={error} />
  }

  if (isLoading) {
    return <Skeleton className="h-1 w-4" />
  }

  if (!data || data.inspection.immutabilitySetBy === "") {
    return <span>Bericht nicht freigegeben</span>
  }

  return <div className="items-start">Tim Olbrich</div>
}
