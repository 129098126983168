export async function previewResponseHandler(response: Response) {
  if (response.status !== 200) {
    return response.json()
  }
  return {
    objectURL: window.URL.createObjectURL(await response.blob()),
  }
}

export async function downloadResponseHandler(response: Response) {
  if (response.status !== 200) {
    return response.json()
  }
  // attempt to retrieve the filename from content-disposition header
  const contentDisposition = response.headers.get("Content-Disposition")
  // fallback filename
  let filename = "Prüfbericht.pdf"
  if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(contentDisposition)
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "")
    }
  }
  return {
    filename,
    objectURL: window.URL.createObjectURL(await response.blob()),
  }
}
