import { ReactElement } from "react"
import { formatDate, formatTime } from "@/shared/utilities/date"
import { Process } from "@/services/backend/processes/process/process"

interface ProcessCreatedAtProps {
  process: Process
}

export function ProcessCreatedAt({
  process,
}: ProcessCreatedAtProps): ReactElement {
  return (
    <div className="items-start">
      <div className="flex flex-col items-start">
        <span className="font-bold">{formatDate(process.createdAt)}</span>
        <span>{formatTime(process.createdAt)}</span>
      </div>
    </div>
  )
}
