import PutBatchDialog from "@/routes/base-data/sample/batches/_components/put-batch-dialog"
import { BatchListTable } from "@/routes/base-data/sample/batches/_components/batch-list-table"

export function BatchesRoute() {
  return (
    <div className="flex flex-col space-y-4">
      <PutBatchDialog />
      <BatchListTable />
    </div>
  )
}
