import type { RouteObject } from "react-router-dom"
import { documentationsRoutes } from "@/routes/processes/[processId]/process/documentations/documentations-routes"
import { DocumentationsRoute } from "@/routes/processes/[processId]/process/documentations/documentations-route"
import { AnalyticsRoute } from "@/routes/processes/[processId]/process/analytics/analytics-route"
import { analyticsRoutes } from "@/routes/processes/[processId]/process/analytics/analytics-routes"
import { SetOverviewRoute } from "@/routes/processes/[processId]/process/set-overview/set-overview-route"
import { setOverviewRoutes } from "@/routes/processes/[processId]/process/set-overview/set-overview-routes"
import OverviewRoute from "@/routes/processes/[processId]/process/overview/overview"
import ContactRoute from "./contact/contact"
import { DifferentiationRoute } from "./differentiation/differentiation-route"
import { differentiationRoutes } from "./differentiation/differentiation-routes"
import { InspectionsRoute } from "./inspections/inspections-route"
import { inspectionsRoutes } from "./inspections/inspections-routes"

export const processRoutes: RouteObject[] = [
  {
    path: "overview",
    element: <OverviewRoute />,
    index: true,
  },
  {
    path: "contact",
    element: <ContactRoute />,
  },
  {
    path: "set-overview",
    element: <SetOverviewRoute />,
    children: setOverviewRoutes,
  },
  {
    path: "documentation",
    element: <DocumentationsRoute />,
    children: documentationsRoutes,
  },
  {
    path: "analytics",
    element: <AnalyticsRoute />,
    children: analyticsRoutes,
  },
  {
    path: "differentiation",
    element: <DifferentiationRoute />,
    children: differentiationRoutes,
  },
  {
    path: "inspections",
    element: <InspectionsRoute />,
    children: inspectionsRoutes,
  },
]
