import { useParams } from "react-router-dom"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import { skipToken } from "@reduxjs/toolkit/query"
import { format } from "date-fns"
import ProcedureTable from "./procedure-table"

const getTimestamp = (timestamp: string): string => {
  const formattedDate = format(timestamp, "dd.MM.yyyy")
  const formattedTime = format(timestamp, "HH:mm:ss")
  return `${formattedDate} - ${formattedTime}`
}

export function AnalyticsIndexRoute() {
  const { processId, reportId } = useParams()
  const { data: process } = useGetProcessQuery(
    processId != null ? { id: processId } : skipToken,
  )

  return (
    <div className="flex flex-col space-y-4">
      {process != null && (
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-2">
            <h4 className="font-bold">Inkubationsstart:</h4>
            <p>{getTimestamp(process.incubationStart)}</p>
          </div>
        </div>
      )}

      <ProcedureTable
        label="1N-RLT"
        processId={processId as string}
        reportId={reportId as string}
        procedureType={ProcedureType.RLT1N}
      />

      <ProcedureTable
        label="2N-RLT"
        processId={processId as string}
        reportId={reportId as string}
        procedureType={ProcedureType.RLT2N}
      />
    </div>
  )
}
