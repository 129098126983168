import { useParams } from "react-router-dom"
import { SampleType } from "@/services/backend/samples/sample-set/sample-set"
import { PersistenceStateUsingContext } from "@/shared/persistence-state/persistence-state-info"
import { SampleSetTable } from "./sample-set-table"

export function SetOverviewIndexRoute() {
  const { processId, reportId } = useParams()
  return (
    <div className="flex flex-col">
      <div className="flex w-full flex-row justify-end">
        <PersistenceStateUsingContext />
      </div>
      <div className="flex flex-col space-y-4">
        <SampleSetTable
          reportId={reportId as string}
          label="Oberflächenkeimproben"
          visibilityState={{ airSamplerHoleCount: false, airSampler: false }}
          processId={processId as string}
          sampleType={SampleType.SurfaceMicrobial}
        />
        <SampleSetTable
          reportId={reportId as string}
          label="Luftkeimproben"
          processId={processId as string}
          sampleType={SampleType.AirMicrobial}
        />
      </div>
    </div>
  )
}
