import { NavLink } from "react-router-dom"
import { Edit } from "lucide-react"
import { Card } from "@/shared/shadcn/components/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/shadcn/components/ui/table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { Button } from "@/shared/shadcn/components/ui/button"
import { de } from "date-fns/locale"
import { formatDate, formatTime } from "@/shared/utilities/date"
import { useListInspectionsQuery } from "@/services/backend/inspections/service"

export function ReportListTable(props: { processId: string }) {
  const { processId } = props
  const {
    data: list,
    isLoading,
    error,
  } = useListInspectionsQuery({
    processId,
    limit: 0,
    offset: 0,
  })

  if (isLoading) {
    return (
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Erstellungsdatum</TableHead>
              <TableHead>Festgeschrieben</TableHead>
              <TableHead>Festgeschrieben am</TableHead>
              <TableHead>Festgeschrieben durch</TableHead>
              <TableHead>Aktionen</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell
                className="animate-pulse text-center text-muted-foreground"
                colSpan={2}
              >
                Lade Berichte...
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    )
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (list == null || list.items == null || list.items.length === 0) {
    return (
      <Card className="flex items-center justify-center p-8">
        Keine Berichte vorhanden
      </Card>
    )
  }

  return (
    <Card>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Erstellungsdatum</TableHead>
            <TableHead>Festgeschrieben</TableHead>
            <TableHead>Festgeschrieben am</TableHead>
            <TableHead>Festgeschrieben durch</TableHead>
            <TableHead>Aktionen</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {list!.items!.map(report => (
            <TableRow key={report.id}>
              <TableCell>
                <div className="flex flex-col items-start">
                  <span className="font-bold">
                    {formatDate(report.createdAt, de)}
                  </span>
                  <span>{formatTime(report.createdAt, de)}</span>
                </div>
              </TableCell>
              <TableCell>{report.immutable ? "Ja" : "Nein"}</TableCell>
              <TableCell>
                {report.immutable ? (
                  <div className="flex flex-col items-start">
                    <span className="font-bold">
                      {formatDate(report.createdAt, de)}
                    </span>
                    <span>{formatTime(report.createdAt, de)}</span>
                  </div>
                ) : (
                  "Nicht festgeschrieben"
                )}
              </TableCell>
              <TableCell>
                {report.immutable
                  ? report.immutabilitySetBy
                  : "Nicht festgeschrieben"}
              </TableCell>
              <TableCell>
                <NavLink to={report.id}>
                  <Button size="sm" className="-my-2">
                    <Edit className="h-4 w-4" />
                  </Button>
                </NavLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}
