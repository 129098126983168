import { useParams } from "react-router-dom"
import { H2, P } from "@/shared/components/typography"
import { useContactsListQuery } from "@/services/backend/contacts/contact/service"
import { Contact } from "@/services/backend/contacts/contact/contact"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import {
  ContactInfoCard,
  ContactInfoCardSkeleton,
} from "@/shared/components/domain/contacts/contact-info-card"

export default function ContactRoute() {
  const { processId } = useParams()
  const { data: process } = useGetProcessQuery(
    { id: processId as string },
    { skip: !processId },
  )

  const {
    data: contacts,
    error,
    isLoading,
  } = useContactsListQuery(
    { customerId: process?.customerId as string, limit: 0, offset: 0 },
    { skip: !process },
  )

  if (isLoading) {
    return <ContactInfoCardSkeleton />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <div className="space-y-4">
      <H2>Ansprechpartner</H2>
      <P className="text-muted-foreground">
        Hier werden alle Ansprechpartner angezeigt welche mit dem Vorgang oder
        dem Kunden verbunden sind.
      </P>
      <div className="grid w-fit grid-cols-1 gap-4 lg:grid-cols-2">
        {contacts?.data?.map((contact: Contact) => (
          <ContactInfoCard contact={contact} />
        ))}
      </div>
    </div>
  )
}
