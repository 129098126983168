import { backendApi } from "@/services/api"
import type { Mould } from "@/services/backend/mould/mould"
import { SampleError } from "../samples/sample-error/sample-error"

const mouldsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    createMould: build.mutation<SampleError, PutRequest>({
      query: request => ({
        url: `/inspections/moulds`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Mould"],
    }),
    getMould: build.query<Mould, GetRequest>({
      query: request => ({
        url: `/inspections/moulds`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Mould", id: request.id },
      ],
    }),
    listMoulds: build.query<List, ListRequest>({
      query: request => ({
        url: `/inspections/moulds/list`,
        method: "GET",
        params: request,
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Mould" as const,
                id,
              })),
              { type: "Mould", id: "LIST" },
            ]
          : [{ type: "Mould", id: "LIST" }],
    }),
  }),
})

export const { useCreateMouldMutation, useGetMouldQuery, useListMouldsQuery } =
  mouldsApi

export interface PutRequest {
  id: string
  description: string
}

export interface ListRequest {
  searchString?: string
}

export interface List {
  data: Mould[]
}

export interface GetRequest {
  id: string
}
