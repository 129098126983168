import { AirSamplersTable } from "./_components/air-samplers-table"
import PutAirSamplerDialog from "./_components/put-airsampler-dialog"

export function AirSamplersRoute() {
  return (
    <div className="flex flex-col space-y-4">
      <PutAirSamplerDialog />
      <AirSamplersTable />
    </div>
  )
}
