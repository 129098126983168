import { useStartProcedureMutation } from "@/services/backend/procedures/procedure/service"
import ConfirmationDialog from "@/shared/components/domain/confirmation/confirmation-dialog"
import {
  useErrArtefactNotReady,
  useSetErrArtefactNotReadyFromRtk,
} from "@/shared/service-manager/artefact/err-artefact-not-ready"
import { Button } from "@/shared/shadcn/components/ui/button"
import { ReactElement } from "react"

interface StartIncubationButtonProps {
  processId: string
}

export default function StartIncubationButton({
  processId,
}: StartIncubationButtonProps): ReactElement {
  const [startProcess, { error, reset }] = useStartProcedureMutation()
  const { resetNotReadyError } = useErrArtefactNotReady()

  useSetErrArtefactNotReadyFromRtk(error, () => {
    reset()
  })

  const startIncubation = () => {
    resetNotReadyError()
    startProcess({
      processId: processId as string,
    })
  }

  return (
    <ConfirmationDialog
      trigger={
        <Button variant="outline">
          <p>Inkubation starten</p>
        </Button>
      }
      title="Inkubation starten"
      description="Hiermit wird der Zählprozess in Gang gesetzt."
      onConfirm={() => startIncubation()}
    />
  )
}
