import { backendApi } from "@/services/api"
import type { Filter } from "@/shared/lib/filter/filter"
import type {
  Condition,
  State,
} from "@/services/backend/deliveries/state/state"
import type { Delivery } from "@/services/backend/deliveries/delivery/delivery"

const deliveriesApi = backendApi.injectEndpoints({
  endpoints: build => ({
    deliveriesList: build.query<List, ListRequest>({
      query: request => ({
        url: `/inspections/deliveries/list`,
        method: "GET",
        params: {
          stateValues: request.state.values,
          orderBy: request.orderBy,
          limit: request.limit,
          offset: request.offset,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Delivery" as const,
                id,
              })),
              { type: "Delivery", id: "LIST" },
            ]
          : [{ type: "Delivery", id: "LIST" }],
    }),
    putDelivery: build.mutation<Delivery, PutDeliveryRequest>({
      query: request => ({
        url: `/inspections/deliveries`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Delivery"],
    }),
    getDelivery: build.query<Delivery, GetRequest>({
      query: request => ({
        url: `/inspections/deliveries`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Delivery", id: request.id },
      ],
    }),
  }),
})

export const {
  useDeliveriesListQuery,
  useGetDeliveryQuery,
  usePutDeliveryMutation,
} = deliveriesApi

export interface UpdateConditionRequest {
  id: string
  condition: string
}

export interface GetRequest {
  id: string
}

export interface DeleteRequest {
  id: string
}

export interface ListRequest {
  state: Filter<State>
  orderBy: string[]
  limit: number
  offset: number
}

export interface List {
  count: number
  data: Delivery[]
}

export interface PutDeliveryRequest {
  id: string
  customerId?: string
  description?: string
  shippingDate?: string
  deliveryDate?: string
  creationDate?: string
  deliveryState?: State
  packageCondition?: Condition
  isComplete?: boolean
  trackingId?: string
}
