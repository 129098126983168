import { NavLink, useLocation } from "react-router-dom"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shared/shadcn/components/ui/breadcrumb"

const subPageLabel: { [key: string]: string } = {
  "sample-errors": "Probenfehler",
  moulds: "Schimmelpilze",
  batches: "Chargen",
  airsamplers: "Luftkeimsammler",
}

export function BreadcrumbsToBasedata() {
  const location = useLocation()
  const subPage = location.pathname.split("/").pop()

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem className="hidden md:block">
          <BreadcrumbLink asChild>
            <NavLink to="/base-data/sample-errors">
              <span className="font-mono">Stammdaten</span>
            </NavLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="hidden md:block" />
        {subPage && (
          <BreadcrumbItem>
            <BreadcrumbPage>{subPageLabel[subPage]}</BreadcrumbPage>
          </BreadcrumbItem>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
