import { Condition, State } from "@/services/backend/deliveries/state/state"
import {
  EvaluableType,
  GrowthMedium,
  ProcedureType,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"

export const sampleTypes: { [key: string]: string } = {
  [SampleType.AirMicrobial]: "aLK",
  [SampleType.SurfaceMicrobial]: "AK",
}

export const procedureTypes: { [key: string]: string } = {
  [ProcedureType.RLT1N]: "1N-RLT",
  [ProcedureType.RLT2N]: "2N-RLT",
}

export const growthMedia: { [key: string]: string } = {
  [GrowthMedium.Caso]: "Caso",
  [GrowthMedium.Malt]: "Malz",
  [GrowthMedium.DG18]: "DG18",
}

export const evaluableTypes: { [key: string]: string } = {
  [EvaluableType.Evaluable]: "Auswertbar",
  [EvaluableType.NotEvaluable]: "Nicht auswertbar",
}

export const packageConditions: { [key: string]: string } = {
  [Condition.Fine]: "In Ordnung",
  [Condition.Damaged]: "Beschädigt",
  [Condition.Rejected]: "Zurückgewiesen",
}

export const deliveryState: { [key: string]: string } = {
  [State.Announced]: "Angekündigt",
  [State.InDelivery]: "In Lieferung",
  [State.Delivered]: "Angekommen",
}

export const artefactLabel: { [key: string]: string } = {
  overview: "Übersicht",
  contact: "Ansprechpartner",
  documentation: "Dokumentation",
  "set-overview": "Probensetübersicht",
  analytics: "Zählung",
  differentiation: "Differenzierung",
  reports: "Prüfberichte",
}
