import { backendApi } from "@/services/api"
import { Procedure } from "@/services/backend/procedures/procedure/procedure"
import { ProcedureType } from "../../samples/sample-set/sample-set"

const procedureApi = backendApi.injectEndpoints({
  endpoints: build => ({
    startProcedure: build.mutation<
      StartProcedureResponse,
      StartProcedureRequest
    >({
      query: request => ({
        url: `/inspections/procedures`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["Procedures", "SampleSet", "Process"],
    }),
    putProcedure: build.mutation<Procedure, PutRequest>({
      query: request => ({
        url: `/inspections/procedures`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Procedures"],
    }),
    calculate: build.mutation<CalculateResponse, CalculateRequest>({
      query: request => ({
        url: `/inspections/procedures/calculate`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["Procedures"],
    }),
    listProcedures: build.query<List, ListProceduresRequest>({
      query: request => ({
        url: `/inspections/procedures/list`,
        method: "GET",
        params: {
          processId: request.processId,
          procedureType: request.procedureType,
          limit: request.limit,
          page: request.page,
          ownerId: request.ownerId,
          ownerType: request.ownerType,
        },
      }),
      providesTags: ["Procedures"],
    }),
    showProcedureBySampleSet: build.query<
      Procedure,
      ShowProcedureBySampleSetRequest
    >({
      query: request => ({
        url: `/inspections/procedures/samplesets`,
        method: "GET",
        params: request,
      }),
      providesTags: ["Procedures"],
    }),
    addMeasurement: build.mutation<
      AddMeasurementResponse,
      AddMeasurementRequest
    >({
      query: request => ({
        url: `/inspections/procedures/incubations/measurements`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Procedures"],
    }),
    addMeasurements: build.mutation<
      AddMeasurementsResponse,
      AddMeasurementsRequest
    >({
      query: request => ({
        url: `/inspections/procedures/incubations/measurements`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["Procedures"],
    }),
  }),
})

export const {
  usePutProcedureMutation,
  useStartProcedureMutation,
  useListProceduresQuery,
  useCalculateMutation,
  useAddMeasurementMutation,
  useAddMeasurementsMutation,
  useShowProcedureBySampleSetQuery,
} = procedureApi

interface StartProcedureRequest {
  processId: string
}

export interface PutRequest {
  id: string
  sampleSetId: string
  processId: string
  description?: string
  comment?: string
  holeCount?: number
}

interface StartProcedureResponse {}

interface ListProceduresRequest {
  limit: number
  page: number
  procedureType: ProcedureType
  processId: string
  ownerId?: string
  ownerType?: string
}

interface List {
  count: number
  data: Procedure[]
}

export interface CalculateRequest {
  processId: string
}

export interface CalculateResponse {}

export interface AddMeasurementRequest {
  incubationId: string
  procedureId: string
  measurementId: string
  measurementTime: string
  value?: number
  employeeId: string
  exceedsThreshold: boolean
}

interface AddMeasurementResponse {}

export interface AddMeasurementsRequest {
  processId: string
  measurements: AddMeasurementRequest[]
}

interface AddMeasurementsResponse {}

interface ShowProcedureBySampleSetRequest {
  sampleSetId: string
}
