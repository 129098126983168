import { useGetBatchQuery } from "@/services/backend/batches/batch/service"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import { formatDate } from "@/shared/utilities/date"
import { de } from "date-fns/locale"

interface BatchComponentProps {
  batchId: string
}

export function BatchComponent({ batchId }: BatchComponentProps) {
  const { data, error } = useGetBatchQuery({ id: batchId })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (data != null) return <span>{formatDate(data.expiryDate, de)}</span>

  return <span>Keine Charge gefunden</span>
}
