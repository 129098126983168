import {
  Incubation,
  Measurement,
} from "@/services/backend/procedures/procedure/procedure"
import { ReactElement } from "react"

interface MeasurementInputProps {
  incubation: Incubation
}

export default function MeasurementLabel({
  incubation,
}: MeasurementInputProps): ReactElement {
  const measurement = incubation.measurements.find(
    (measure: Measurement) => measure.isCurrent,
  )

  if (!measurement) {
    return <div>Keine Zählung gefunden</div>
  }

  if (measurement.exceedsThreshold) {
    return <div>{">100"}</div>
  }

  return <div>{measurement.measurement}</div>
}
