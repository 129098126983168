import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import { LucideIcon, Search } from "lucide-react"
import { Button } from "@/shared/shadcn/components/ui/button"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import type { ListRequest } from "@/services/backend/processes/process/service"
import { useProcessesListQuery } from "@/services/backend/processes/process/service"
import type { Process } from "@/services/backend/processes/process/process"
import { CustomerName } from "@/shared/components/domain/customer/customer-name"
import { ProcessNumber } from "@/shared/components/domain/processes/process-number"
import { InspectionCreatedAt } from "@/shared/components/domain/inspection/inspection-created-at"
import { InspectionReleasedAt } from "@/shared/components/domain/inspection/inspection-released-at"
import { InspectionCreatedFrom } from "@/shared/components/domain/inspection/inspection-created-from"
import { InspectionReleasedFrom } from "@/shared/components/domain/inspection/inspection-released-from"
import {
  processStateIcon,
  StatusIcon,
} from "@/shared/components/domain/processes/get-status-label"
import { ProcessCreatedAt } from "@/shared/components/domain/processes/process-created-at"

const tableColumns: ColumnDef<Process>[] = [
  {
    id: "Vorgangs-Nr.",
    accessorKey: "processNumber",
    header: "Vorgangs-Nr.",
    cell: ({ row }) => {
      const processData = row.original
      return <ProcessNumber processId={processData.id} />
    },
  },
  {
    id: "createdAt",
    header: "Prozess erstellt am",
    cell: ({ row }) => <ProcessCreatedAt process={row.original} />,
  },
  {
    id: "Kunde",
    accessorKey: "customerId",
    header: "Kunde",
    cell: ({ row }) => {
      const processData = row.original
      return <CustomerName customerId={processData.customerId} />
    },
  },
  {
    id: "Status",
    header: "Status",
    accessorKey: "Status",
    cell: ({ row }) => {
      const icon: LucideIcon = processStateIcon[row.original.status]
      return <StatusIcon icon={icon} />
    },
  },
  {
    id: "inspectionCreated",
    header: "Prüfbericht erstellt",
    cell: ({ row }) => <InspectionCreatedAt processId={row.original.id} />,
  },
  {
    id: "inspectionCreatedFrom",
    header: "Erstellt von",
    cell: ({ row }) => <InspectionCreatedFrom processId={row.original.id} />,
  },
  {
    id: "inspectionReleased",
    header: "Prüfbericht freigeben",
    cell: ({ row }) => <InspectionReleasedAt processId={row.original.id} />,
  },
  {
    id: "inspectionReleasedFrom",
    header: "Freigegeben von",
    cell: ({ row }) => <InspectionReleasedFrom processId={row.original.id} />,
  },
  {
    id: "Aktionen",
    header: "Aktionen",
    cell: ({ row }) => {
      const processData = row.original
      return (
        <NavLink to={`${processData.id}/contact`}>
          <Button variant="ghost" size="sm" className="-mb-2.5 -mt-1.5">
            <Search className="h-4 w-4" />
          </Button>
        </NavLink>
      )
    },
  },
]

export function ProcessesListTable() {
  const [request] = useState<ListRequest>({
    limit: 50,
    offset: 0,
  })
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useProcessesListQuery(request, {
    refetchOnMountOrArgChange: true,
  })

  const table = useDataTableController(tableColumns, list?.data || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <DataTableContainer>
      <DataTable table={table} columns={tableColumns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  )
}
